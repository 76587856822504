.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
}

.container-1440 {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
}